<script>
import popup from '../components/popup.vue'
import { ref, onMounted, inject, watch } from 'vue'
import { gsap, ScrollTrigger, Draggable, Flip, MotionPathPlugin } from 'gsap/all'
import { useI18n } from 'vue-i18n'
gsap.registerPlugin(ScrollTrigger, Draggable, Flip, MotionPathPlugin)

export default {
  name: 'HomeView',
  components: { popup },
  props: {
    window: Object
  },
  setup() {
    const $axios = inject('$axios') // $axios 주입 받기
    const videoPlayer = ref(null)
    onMounted(() => {
      if (window.width > 768) {
        setTimeout(() => {
          videoPlayer.value.play()
        }, 3000)
      }
    })
    const { locale } = useI18n()
    const language_set = ref(locale.value)
    const by_date = ref([])

    watch(() => locale.value, (newValue) => {
      console.log('switch')
      language_set.value = newValue
      get_news_by_date()
    })

    const get_news_by_date = async () => {
      try {
        const response = await $axios.post('https://lexcode.co.kr/app/get_newsletter_by_date_main', null, {
          params: {
            locale: language_set.value
          }
        })
        by_date.value = response.data
        // console.log(response.data);
      } catch (error) {
        console.error(error)
      }
    }

    function formatDate(dateString) {
      const date = new Date(dateString)
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
      return formattedDate
    }
    onMounted(() => {
      get_news_by_date()
    })
    return {
      videoPlayer,
      by_date
    }
  },
  data() {
    return {
      first_title: true,
      lastScrollPosition: 0,
      scrollValue: 0,
      activeIndex: 1,
      tabItems: [
        { index: 1, title: 'LEX-Cloud' },
        { index: 2, title: 'TransCreation' },
        { index: 3, title: 'CAT-X' },
        { index: 4, title: 'Journal Lab' },
        { index: 5, title: 'BAVL' }
      ],
      stories: [
        {
          id: 469,
          en_id: 571,
          title: 'main.stories1_title', // 로컬리제이션 스트림라인 구축
          description: 'main.stories1_desc', // 효율적인 로컬리제이션 업무를 위해 HD현대로보틱스 전용 커스텀 플랫폼을 구축하여 협업...
          imageSrc: require('../assets/img/main_sec4_img3.png'),
          logoSrc: require('../assets/img/main_sec4_img3-logo.png'),
          company: 'main.stories1_company' // HD현대로보틱스
        },
        {
          id: 470,
          en_id: 578,
          title: 'main.stories2_title', // 생성형 AI 기반 번역 솔루션 구축
          description: 'main.stories2_desc', // TransCreation은 생성형 AI를 기반으로 한 번역 솔루션으로, 언어 제한 없는 자동번역...
          imageSrc: require('../assets/img/main_sec4_img2.png'),
          logoSrc: require('../assets/img/main_sec4_img2-logo.png'),
          company: 'main.stories2_company' // Transcreation
        },
        {
          id: 471,
          en_id: 579,
          title: 'main.stories3_title', // 전문 용어 다국어 DB 연구 및 커스텀 AI 번역 엔진 탑재
          description: 'main.stories3_desc', // AI 번역 엔진으로 전문 분야에 고도화된 자동 번역 플랫폼을 통해 ICAO(국제민...
          imageSrc: require('../assets/img/main_sec4_img1.png'),
          logoSrc: require('../assets/img/main_sec4_img1-logo.png'),
          company: 'main.stories3_company' // 국립항공박물관
        },
        {
          id: 472,
          en_id: 580,
          title: 'main.stories4_title', // AI 논문 에디팅 및 분석 시스템
          description: 'main.stories4_desc', // 초거대 인공지능 모델과 자연어 처리 기술을 활용하여 개발된 AI 논문 분석 시스템 DeepR...
          imageSrc: require('../assets/img/main_sec4_img4.png'),
          logoSrc: require('../assets/img/main_sec4_img4-logo.png'),
          company: 'main.stories4_company' // DeepRead 2.0
        },
        {
          id: 473,
          en_id: 571,
          title: 'main.stories5_title', // AI 학습용 데이터 구축
          description: 'main.stories5_desc', // BAVL 크라우드 소싱 플랫폼을 활용하여 150만 개 이상의 영어 문장 데이터를 ...
          imageSrc: require('../assets/img/main_sec4_img5.png'),
          logoSrc: require('../assets/img/main_sec4_img5-logo.png'),
          company: 'main.stories5_company' // NIA한국지능정보사회진흥원
        }
      ],
      contentItems: [
        {
          index: 1,
          class: 'lc',
          logoSrc: require('../assets/img/main_sec3_1_logo.png'),
          title: 'LEX-Cloud',
          subtitle: 'menu.lc_subtitle', // 번역 · ...
          description: 'main.lc_desc', // 인공지능과 전문가의...
          chartTitle: 'main.lc_chartTitle', // 렉스클라우드 플랫폼...
          chartSrc: 'main_section3_lexcloud',
          rightImgSrc: 'main_section3_lexcloud2',
          legend: [
            { title: 'main.lc_legend1' }, // 누적 진행 프로젝트
            { title: 'main.lc_legend2' } // 함께한 고객 수
          ]
        },
        {
          index: 2,
          class: 'gas',
          title: 'TransCreation',
          subtitle: 'menu.tc_subtitle', // 생성형 A...
          description: 'main.tc_desc', // 생성형 인공지능과...
          chartTitle: 'main.tc_chartTitle', // 생성형 AI...
          chartSrc: 'main_section3_gas',
          rightImgSrc: 'main_section3_gas2'
        },
        {
          index: 3,
          class: 'catx',
          title: 'CAT-X',
          subtitle: '',
          description: 'main.catx_desc', // 분야별 130만...
          chartTitle: 'main.catx_chartTitle', // 주요 분야...
          chartSrc: 'main_section3_cmtx',
          rightImgSrc: 'main_section3_cmtx2'
        },
        {
          index: 4,
          class: 'jl',
          logoSrc: require('../assets/img/main_sec3_4_logo.png'),
          title: 'Journal Lab',
          subtitle: 'menu.jl_subtitle', // 인공지능 ...
          description: 'main.jl_desc', // 자연어처리 모델...
          chartTitle: 'main.jl_chartTitle', // 연도별 논문...
          unit: 'main.jl_unit1', // 단위 : 건
          chartSrc: 'main_section3_jl',
          rightImgSrc: 'main_section3_jl2'
        },
        {
          index: 5,
          class: 'bavl',
          logoSrc: require('../assets/img/main_sec3_5_logo.png'),
          title: 'BAVL',
          subtitle: 'menu.bavl_subtitle', // 자연어 데...
          description: 'main.bavl_desc', // 다양한 연구...
          chartTitle: 'main.bavl_chartTitle', // 카테고리별언어 데이터...
          unit: 'main.bavl_unit', // 단위 : 개
          chartSrc: 'main_section3_bavl',
          rightImgSrc: 'main_section3_bavl2'
        }
      ]
    }
  },
  methods: {
    onScroll() {
      if (window.pageYOffset < 0) {
        return
      }
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < (window.innerHeight / 2)) {
        return
      }
      this.first_title = window.pageYOffset < this.lastScrollPosition
      this.lastScrollPosition = window.pageYOffset
    },
    zoomIn(event) {
      gsap.set('.landing', {
        opacity: 0,
        scale: 1,
        duration: 3,
        delay: 1
      })
      gsap.to('.landing', {
        opacity: 1,
        duration: 1,
        scale: 1,
        transformOrigin: 'center 52%'
      })
      gsap.to('.landing', {
        scale: 400,
        duration: 2,
        delay: 2,
        display: 'none'
      })
    },
    handleIntersection(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.$nextTick(() => {
            // 섹션이 화면에 보일 때 비디오 재생
            this.$refs.videoPlayer.play()
          })
        } else {
          this.$nextTick(() => {
            // 섹션이 화면에서 사라질 때 비디오 일시 정지
            this.$refs.videoPlayer.pause()
          })
        }
      })
    },
    tabActive(index) {
      this.activeIndex = index
    },
    isActiveItem(item) {
      return this.activeIndex === item.index
    },
    filteredStories(position) {
      return this.stories.filter((item, index) => {
        return (position === 'left' && index % 2 === 0) ||
          (position === 'right' && index % 2 !== 0)
      })
    },
    getVideoSource(item, lang) { // 지정된 아이템의 언어에 맞는 비디오 소스를 반환하는 함수
      return require(`../assets/img/${item.rightImgSrc}_${lang}.mp4`)
    },
    getVideoSource2(item, lang) { // 지정된 아이템의 언어에 맞는 비디오 소스를 반환하는 함수
      return require(`../assets/img/${item.chartSrc}_${lang}.mp4`)
    }
  },
  computed: {
    // GIF 로드 문제로 개별 로드보다 한번에 로드하고 v-show로 구분하는 방식으로 변경
    // filteredContentItems () {
    //   return this.contentItems.filter(item => this.isActiveItem(item))
    // }
  },
  mounted() {
    try {
      const options = {
        threshold: 0.5 // 섹션이 화면에 50% 이상 보일 때 감지
      }
      const observer = new IntersectionObserver(this.handleIntersection, options)
      observer.observe(this.$refs.section1)
      this.lastScrollPosition = window.pageYOffset
      window.addEventListener('scroll', this.onScroll)
      window.addEventListener('scroll', this.goto)
      this.$nextTick(function () {
        setTimeout(() => {
          this.zoomIn()
        }, 250)
      })
    } catch (e) {
      console.error('Error occurred in mounted hook:', e)
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('scroll', this.goto)
  }
}
</script>
<template>
  <div class="main">
    <div v-if="window.width > 768" class="landing">
      <section class="inner_1"></section>
      <section class="inner_0"></section>
    </div>
    <template v-if="window.width > 768">
      <div class="sticky_wrap">
        <section class="section1" ref="section1">
          <div class="container">
            <transition name="full-fade">
              <div class="" key="down" v-if="first_title">
                <video ref="videoPlayer" muted controls>
                  <source src="../assets/img/main.mp4" type="video/mp4">
                </video>
                <div class="overlay"></div>
                <div class="text_wrap first main_title t_white t_center" data-aos="fade-down" data-aos-duration="700">
                  <h1>{{ $t("main.sec1_title")/* 사람과 AI의 협업으로 언어 장벽을 */ }}<br>{{ $t("main.sec1_title2")/* 무한한 기회로 바꾸어
                    갑니다.
                    */}}</h1>
                  <h4 class="mgt">{{ $t("main.sec1_subtitle")/* 1%가 사용하는 한국어 너머 99%의 기회를 고객에게 전달하는 일, 그것이 우리의 미션입니다. */
                    }}
                  </h4>
                </div>
              </div>
              <div class="main_title t_white after" v-else>
                <div class="text_wrap t_center">
                  <h4 class="" data-aos="fade-down" data-aos-duration="500">{{ $t("main.sec2_subtitle")/* 언어는 데이터가 되고,
                    데이터는 기회가 되는 */}}</h4>
                  <h1 data-aos="fade-down" data-aos-duration="500">{{ $t("main.sec2_title")/* LEXCODE의 자연어처리 솔루션 */ }}
                  </h1>
                </div>
                <img src="../assets/img/main_sec1.gif" />
              </div>
            </transition>
          </div>
        </section>
      </div>
    </template>
    <template v-else>
      <section class="section1" ref="section1">
        <div class="container">
          <div class="" key="down">
            <video ref="videoPlayer" muted controls>
              <source src="../assets/img/main.mp4" type="video/mp4">
            </video>
            <div class="overlay"></div>
            <div class="text_wrap first main_title t_white t_center" data-aos="fade-down">
              <h1>{{ $t("main.sec1_title")/* 사람과 AI의 협업으로 언어 장벽을 */ }}<br>{{ $t("main.sec1_title2")/* 무한한 기회로 바꾸어 갑니다.
                */ }}
              </h1>
              <h4 class="mgt">{{ $t("main.sec1_subtitle")/* 1%가 사용하는 한국어 너머 99%의 기회를 고객에게 전달하는 일, 그것이 우리의 미션입니다. */ }}
              </h4>
            </div>
          </div>
        </div>
      </section>
    </template>
    <section class="section3">
      <div class="container">
        <div class="text_wrap main_title">
          <h4>Business</h4>
          <h1>{{ $t("main.sec3_title")/* 렉스코드의 솔루션을 소개합니다. */ }}</h1>
        </div>
        <template v-if="window.width > 768">
          <div class="sec3_tab">
            <p v-for="(item, index) in tabItems" :key="index" @click="tabActive(item.index)"
              :class="{ active: activeIndex === item.index }">
              {{ item.title }}
            </p>
          </div>
          <div class="sec3_cont_wrap">
            <div v-for="(item, index) in contentItems" :key="index" :class="['sec3_cont', item.class]"
              v-show="activeIndex == item.index">
              <div class="left_cont">
                <img v-if="item.logoSrc" class="logo" :src="item.logoSrc" loading="lazy">
                <p class="cont_title">{{ $t(item.title) }}<span>{{ $t(item.subtitle) }}</span></p>
                <p class="description">{{ $t(item.description) }}</p>
                <p class="chart_title">{{ $t(item.chartTitle) }}</p>
                <div class="unit" v-if="item.unit">{{ $t(item.unit) }}</div>
                <!-- <img v-if="item.chartSrc" class="chart" :src="item.chartSrc" loading="lazy"> -->
                <template v-if="$i18n.locale == 'ko'">
                  <video autoplay muted loop class="chart">
                    <source :src="getVideoSource2(item, 'ko')" type="video/mp4" />
                  </video>
                </template>
                <template v-else>
                  <video autoplay muted loop class="chart">
                    <source :src="getVideoSource2(item, 'en')" type="video/mp4" />
                  </video>
                </template>
                <div class="legend" v-if="item.legend">
                  <div class="legend_cont" v-for="(legend, index) in item.legend" :key="index">
                    <p class="legend_bullet"></p>
                    <p class="legend_title">{{ $t(legend.title) }}</p>
                  </div>
                </div>
              </div>
              <div class="right_cont" :class="{ 'gas_en': $i18n.locale === 'en' && item.class == 'gas' }">
                <!-- <img v-if="item.rightImgSrc" :src="item.rightImgSrc" loading="lazy"> -->
                <template v-if="$i18n.locale == 'ko'">
                  <template v-if="item.rightImgSrc == 'main_section3_jl2'">
                    <img src="../assets/img/main_section3_jl2.png">
                  </template>
                  <template v-else-if="item.rightImgSrc == 'main_section3_gas2'">

                  </template>
                  <template v-else>
                    <video autoplay muted loop>
                      <source :src="getVideoSource(item, 'ko')" type="video/mp4" />
                    </video>
                  </template>
                </template>
                <template v-else>
                  <template v-if="item.rightImgSrc == 'main_section3_jl2'">
                    <img src="../assets/img/main_section3_jl2.png">
                  </template>
                  <template v-else-if="item.rightImgSrc == 'main_section3_gas2'">

                  </template>
                  <template v-else>
                    <video autoplay muted loop>
                      <source :src="getVideoSource(item, 'en')" type="video/mp4" />
                    </video>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="sec3_cont_wrap">
            <div class="sec3_cont" v-for="item in contentItems" :key="item.index" :class="['sec3_cont', item.class]">
              <div class="title_wrap">
                <img v-if="item.logoSrc" class="logo" :src="item.logoSrc">
                <div class="text_wrap">
                  <p class="cont_subtitle">{{ $t(item.subtitle) }}</p>
                  <p class="cont_title">{{ $t(item.title) }}</p>
                </div>
              </div>
              <p class="description">{{ $t(item.description) }}</p>
              <!-- <img v-if="item.rightImgSrc" :src="item.rightImgSrc" loading="lazy" /> -->
              <template v-if="$i18n.locale == 'ko'">
                <template v-if="item.rightImgSrc == 'main_section3_jl2'">
                  <img src="../assets/img/main_section3_jl2.png">
                </template>
                <template v-else>
                  <video autoplay muted loop>
                    <source :src="getVideoSource(item, 'ko')" type="video/mp4" />
                  </video>
                </template>
              </template>
              <template v-else>
                <template v-if="item.rightImgSrc == 'main_section3_jl2'">
                  <img src="../assets/img/main_section3_jl2.png">
                </template>
                <template v-else>
                  <video autoplay muted loop>
                    <source :src="getVideoSource(item, 'en')" type="video/mp4" />
                  </video>
                </template>
              </template>
              <p class="chart_title">{{ $t(item.chartTitle) }}</p>
              <div v-if="item.unit" class="unit">{{ $t(item.unit) }}</div>
              <!-- <img :src="item.chartSrc" class="chart" loading="lazy" /> -->
              <template v-if="$i18n.locale == 'ko'">
                <video autoplay muted loop class="chart">
                  <source :src="getVideoSource2(item, 'ko')" type="video/mp4" />
                </video>
              </template>
              <template v-else>
                <video autoplay muted loop class="chart">
                  <source :src="getVideoSource2(item, 'en')" type="video/mp4" />
                </video>
              </template>
              <div v-if="item.legend" class="legend">
                <div v-for="(legendItem, index) in item.legend" :key="index" class="legend_cont">
                  <p class="legend_bullet"></p>
                  <p class="legend_title">{{ $t(legendItem.title) }}</p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </section>
    <section class="section4">
      <p class="sticky_text" v-if="window.width > 768" data-aos="fade-down" data-aos-delay="300">success stories</p>
      <div class="container">
        <div class="text_wrap main_title">
          <h4>Success Stories</h4>
          <h1>{{ $t("main.sec4_title")/* 렉스코드, 당신의 성장 파트너 */ }}</h1>
          <div class="flex mgt">
            <h4 class="cont_description">{{ $t("main.sec4_cont_desc")/* 파트너들의 성공 사례를 통해 비지니스를 성장시킬 수 있는 방법에 대해서 알아보세요.
              */}}
            </h4>
            <a class="main_btn" href="/relations/partners">{{ $t("main.sec4_cont_btn")/* 파트너 더보기 → */ }}</a>
          </div>
        </div>
        <template v-if="window.width > 768">
          <div class="flex sec4_cont_wrap">
            <div class="left">
              <router-link v-for="(item) in filteredStories('left')" :key="item.id"
                :to="`/relations/stories/${$i18n.locale == 'ko' ? item.id : item.en_id }`" class="sec4_cont">
                <img :src="item.imageSrc" loading="lazy" />
                <div class="text_wrap">
                  <h2>{{ $t(item.title) }}</h2>
                  <p class="cont_description">{{ $t(item.description) }}</p>
                  <div class="comp_info">
                    <div class="text_wrap">
                      <img :src="item.logoSrc" loading="lazy" />
                      <p class="comp_name">{{ $t(item.company) }}</p>
                    </div>
                    <img class="arrow" src="../assets/img/main_sec4_arrow.png" loading="lazy" />
                  </div>
                </div>
              </router-link>
            </div>
            <div class="right">
              <router-link v-for="(item) in filteredStories('right')" :key="item.id"
                :to="`/relations/stories/${$i18n.locale == 'ko' ? item.id : item.en_id }`" class="sec4_cont">
                <img :src="item.imageSrc" loading="lazy" />
                <div class="text_wrap">
                  <h2>{{ $t(item.title) }}</h2>
                  <p class="cont_description">{{ $t(item.description) }}</p>
                  <div class="comp_info">
                    <div class="text_wrap">
                      <img :src="item.logoSrc" loading="lazy" />
                      <p class="comp_name">{{ $t(item.company) }}</p>
                    </div>
                    <img class="arrow" src="../assets/img/main_sec4_arrow.png" loading="lazy" />
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="overflow_wrap">
            <div class="scroll_wrap">
              <router-link v-for="story in stories" :to="'/relations/stories/' + story.id" class="sec4_cont"
                :key="story.id">
                <img :src="story.imageSrc" loading="lazy" />
                <div class="text_wrap">
                  <h2>{{ story.title }}</h2>
                  <p class="cont_description">{{ story.description }}</p>
                  <div class="comp_info">
                    <div class="text_wrap">
                      <img :src="story.logoSrc" loading="lazy" />
                      <p class="comp_name">{{ story.company }}</p>
                    </div>
                    <img class="arrow" src="../assets/img/main_sec4_arrow.png" loading="lazy" />
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </template>
      </div>
    </section>
    <section class="section5">
      <div class="flex">
        <div class="sec5_left">
          <div class="text_wrap main_title">
            <h4>COMPANY</h4>
            <h1>{{ $t("main.sec5_title")/* 20년간 쌓아온 신뢰를 확인해 보세요. */ }}<br>{{ $t("main.sec5_title2") }}</h1>
            <h4 class="cont_description mgt">{{ $t("main.sec5_cont_desc")/* 작은 물결이 모여 큰 물결이 됐습니다. 지난 20년간 수만 명의 고객으로부터
              언어는
              장벽이 아닌 기회라는 가치를 전달했습니다. 이제 렉스코드는 인간과 AI와의 협업을 통해 새로운 길을개척합니다. */}}</h4>
            <a class="main_btn mgt" href="/company">{{ $t("main.sec5_cont_btn")/* 렉스코드 알아보기 → */ }}</a>
          </div>
        </div>
        <div class="sec5_right">
          <img src="../assets/img/main_sec5_bg.png" />
        </div>
      </div>
    </section>
    <section class="section6">
      <div class="flex">
        <div class="sec6_left">
          <div class="text_wrap main_title">
            <img src="../assets/img/main_sec6_bg.png" />
          </div>
        </div>
        <div class="sec6_right">
          <div class="text_wrap main_title">
            <h4>OUR CULTURE</h4>
            <h1>{{ $t("main.sec6_title")/* 우리가 소통하는 방식 */ }}</h1>
            <h4 class="cont_description mgt">{{ $t("main.sec6_cont_desc")/* 렉스코디언들은 오늘도 새로운 기회를 만들어 가고 있습니다.
              */}}<br>{{ $t("main.sec6_cont_desc2")/* 함께 만들어 가는 우리의 문화 한번 들어보실래요? */ }}</h4>
            <a class="main_btn mgt" href="/relations/recruit">{{ $t("main.sec6_cont_btn")/* 문화 더보기 → */ }}</a>
            <img class="rotate" src="../assets/img/relations_recruit_section1_img2.png" />
          </div>
        </div>
      </div>
    </section>
    <div class="sec7-8wrap">
      <section class="section7 main_padding">
        <div class="container">
          <div class="text_wrap main_title">
            <h4>Monthly Lexcodian</h4>
            <h1>{{ $t("main.sec7_title")/* 통하는 사람들의 통하는 이야기 */ }}</h1>
          </div>
          <div class="sec7_wrap">
            <div class="flex">
              <div class="sec7_cont" v-for="(item, index) in by_date" :key="index">
                <router-link :to="`/relations/stories/${item.id}`">
                  <div class="sec7_cont_img">
                    <img :src="'https://directory.lexcode.co.kr/newsletter-content' + item.thumbnail_file" />
                  </div>
                  <div class="sec7_cont_info flex">
                    <p class="news_title">{{ item.title }}</p>
                    <p class="news_date">
                      {{ item.created_at.replace("T", ' ') }}
                    </p>
                  </div>
                </router-link>
              </div>
            </div>
            <a href="/relations/stories" class="main_btn">{{ $t("main.sec7_cont_btn")/* 뉴스레터 더보기 */ }}</a>
          </div>
        </div>
      </section>
      <section class="section8 main_padding">
        <div>
          <img src="../assets/img/test.gif" data-aos="fadeIn" data-aos-duration="300" />
        </div>
      </section>
    </div>
  </div>
</template>
